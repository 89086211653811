body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background-color: #151517;
}

.App {
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 430px;
}

/* Content */

.content {
  color: white;
  font-size: 16px;
  padding: 2% 20%;
  
}

.content > div {
  padding-bottom: 40px;
  
}

.content > div:last-of-type {
  border-bottom: none;
}

/* Contact container */

.contact-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.contact-container a {
  color: white;
  margin-right: 10px;
}

.contact-container p {
  padding-bottom: 5px;
  margin: 5px;
}

#about-paragragh{
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive */

@media only screen and (max-width: 1850px) {
  .content {
    padding: 2% 12%;
  }
  #about-paragragh{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1450px) {
  .content {
    padding: 2% 16%;
  }
  #about-paragragh{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1250px) {
  .content {
    padding: 2% 12%;
  }
  #about-paragragh{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 965px) {
  .content {
    padding: 2% 5%;
  }
  #about-paragragh{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 820px) {
  #logo {
    display: none;
  }
}

