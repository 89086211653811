html {
  background-color: #151517;
}

.App {
  text-align: center;
  width: fit-content;
  min-width: 430px;
}

/* Content */

.content {
  color: white;
  font-size: 16px;
  padding: 2% 20%;
  
}

.content > div {
  padding-bottom: 40px;
  
}

.content > div:last-of-type {
  border-bottom: none;
}

/* Contact container */

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container a {
  color: white;
  margin-right: 10px;
}

.contact-container p {
  padding-bottom: 5px;
  margin: 5px;
}

#about-paragragh{
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive */

@media only screen and (max-width: 1850px) {
  .content {
    padding: 2% 12%;
  }
  #about-paragragh{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1450px) {
  .content {
    padding: 2% 16%;
  }
  #about-paragragh{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1250px) {
  .content {
    padding: 2% 12%;
  }
  #about-paragragh{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 965px) {
  .content {
    padding: 2% 5%;
  }
  #about-paragragh{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 820px) {
  #logo {
    display: none;
  }
}
